const isDev = process.env.NODE_ENV === 'development';

const Settings = {
  navTheme: 'light',
  // 拂晓蓝
  primaryColor: '#1890ff',
  layout: 'top',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  // title: '婚禮日記',
  title: false,
  pwa: false,
  logo: '/gitbook_logo.png',
  iconfontUrl: '',
  defaultOpenAll: true,
  ignoreFlatMenu: true,

  liffId: isDev
    ? '1655106492-Jb2xG3EW' //handy.linein.me
    : '1655106492-R3evY9NG', //admin.linein.cc
  businessLottery: isDev
    ? 'https://game.linein.me/businessball/index-src.html'
    : 'https://game.partygo.cc/businessball/',
  stubEnabled: false,
};
export default Settings;
