import { message, notification } from 'antd';
import {
  reqGetMaterialList,
  reqRemoveMaterial,
  reqRefresh,
  getFileListForm,
  reqGetFrameList,
  submitLotteryForm,
  submitGameLotteryForm,
  welcomeMsgForm,
  setWelcomeMsgForm,
  updateForm,
  reqGetUserList,
  reqGetSurveyList,
  reqGetCheckinGsData,
  reqCreateSurvey,
  reqPostSurvey,
  reqGetUserSurveyList,
  reqGetUserSurveyListCheckin,
  reqSetCheckinReady,
  reqSetUserSurveyEnabled,
  reqSetUserSurveyCheckInEnabled,
  reqRemoveUserSurveyAns,
  submitWelcomeImage,
  submitWelcomeVideo,
  reqGetPushMsg,
  reqSetPushMsg,
  reqRemovePushMsg /*reqSetupOnlineService,*/,
  setProgressForm,
  submitUploadFile,
  reqQueryShowInfo,
  reqSetMontage,
  reqSetUserRole,
  reqSetUserPermission,
  reqGetCashMoneyPrompt,
  reqSetCashMoneyPrompt,
  reqAddUsersTag,
  reqRemoveUsersTag,
} from '@/services/customer';
import moment from 'moment';
import { cloneDeep } from 'lodash';

const Model = {
  namespace: 'activityCustomer',
  state: {
    checkinDoneList: {},
    imageList: null,
    listData: null, //activitydata
    montageData: {},
    materialList: null,
    fileList: null,
    welcomeMsg: null,
    frameList: null,
    getUserList: null,
    userSurveyList: null,
    checkinGsData: null,
    checkinGsDataMap: {},
    checkinGsDataList: [],
    userPushMsg: null,
    userListDict: {},
    showInfo: {},
  },
  effects: {
    *submitAdvancedForm({ payload }, { call }) {
      yield call(fakeSubmitForm, payload);
      message.success('提交成功');
    },
    *setProgress({ payload, callback = () => {} }, { call }) {
      console.log('model setProgress');
      // payload.date = moment(payload.date).startOf('day');
      yield call(setProgressForm, payload);
      callback();
      // message.success('抽獎活動已更新');
    },
    *submitGameLottery({ payload }, { call, put }) {
      console.log('model submitGameLottery', payload);
      yield call(submitGameLotteryForm, payload);
      message.success('抽獎活動已更新');

      yield put({
        type: 'refresh',
        payload: payload,
      });
    },
    *submitLottery({ payload }, { call }) {
      console.log('model submitLottery');
      yield call(submitLotteryForm, payload);
      message.success('抽獎活動已更新');
    },
    *uploadWelcomeImage({ payload }, { call }) {
      console.log('model uploadWelcomeImage');
      const r = yield call(submitWelcomeImage, payload);
      if (payload.cb) {
        payload.cb(r);
      }
    },
    *uploadFile({ payload }, { call }) {
      console.log('model uploadFile', payload);
      const r = yield call(submitUploadFile, payload);
      if (payload.cb) {
        payload.cb(r);
      }
    },
    *uploadWelcomeVideo({ payload }, { call }) {
      console.log('model uploadWelcomeVideo');
      const r = yield call(submitWelcomeVideo, payload);
      if (payload.cb) {
        payload.cb(r);
      }
    },
    *queryShowInfo({ payload, callback }, { call, put }) {
      console.log('model queryShowInfo');
      const r = yield call(reqQueryShowInfo, payload);
      yield put({
        type: 'updateShowInfo',
        payload: r,
      });
      if (callback) {
        callback(r);
      }
    },
    *setUserRole({ payload }, { call }) {
      console.log('model setUserRole', payload);
      const r = yield call(reqSetUserRole, payload);
      if (payload.cb) {
        payload.cb(r);
      }
    },

    *setUserPermission({ payload }, { call }) {
      console.log('model setUserPermission', payload);
      const r = yield call(reqSetUserPermission, payload);
      if (payload.cb) {
        payload.cb(r);
      }
    },

    *refresh({ payload }, { call, put }) {
      console.log('refresh', payload);
      // yield put({ type: 'reset'});
      // return
      const response = yield call(reqRefresh, payload);
      yield put({
        type: 'update',
        payload: response,
      });
      // message.success('讀取成功');
    },
    *addUsersTag({ payload, cb }, { call, put }) {
      console.log('addUsersTag', payload);
      yield call(reqAddUsersTag, payload);
      cb && cb();
    },

    *removeUsersTag({ payload, cb }, { call, put }) {
      console.log('removeUsersTag', payload);
      yield call(reqRemoveUsersTag, payload);

      yield put({
        type: 'reduceUserSurveyList',
        payload: payload,
      });
      cb && cb();
    },
    /*
    *setupOnlineService({ payload, callback=()=>{} }, { call, put }) {
      console.log('activityCustomer/setupOnlineService', payload)
      const response = yield call(reqSetupOnlineService, payload);
      if(response!=''){
        load(`https://linein.01.firstline.cc/livechat.f8c0a3f7-27e6-408d-b00d-212d06fa4c4a.js?t=1616057669&once_code=${response}`);
      }
      console.log('activityCustomer/setupOnlineService done', response)
    },
*/
    *submitUpdate({ payload, callback = () => {} }, { call, put }) {
      console.log('activityCustomer/submitUpdate', payload);

      const response = yield call(updateForm, payload);
      message.success('儲存成功');
      callback();
    },

    *getFileList({ payload }, { call, put }) {
      console.log('model getFileList', payload);
      const response = yield call(getFileListForm, payload);
      yield put({
        type: 'reduceFileList',
        payload: response,
      });
      message.success('婚紗照讀取成功');
    },
    *getWelcomeMsg({ payload }, { call, put }) {
      console.log('model getWelcomeMsg', payload);

      const response = yield call(welcomeMsgForm, payload);
      yield put({
        type: 'reduceWelcomeMsg',
        payload: response,
      });
    },
    *setWelcomeMsg({ payload }, { call, put }) {
      console.log('model setWelcomeMsg', payload);
      const response = yield call(setWelcomeMsgForm, payload);
      yield put({
        type: 'reduceWelcomeMsg',
        payload: response,
      });
      // message.success('歡迎詞讀取成功');
    },

    *getUserList({ payload }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqGetUserList, payload);
      yield put({
        type: 'reduceUserList',
        payload: response,
      });
      // message.success('婚紗照讀取成功');
    },

    *removeUserSurveyAns({ payload, callback }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqRemoveUserSurveyAns, payload);
      yield put({
        type: 'getUserSurveyList',
        payload,
      });
      if (callback) callback();
      // message.success('婚紗照讀取成功');
    },

    *getUserSurveyListCheckin({ payload, cb }, { call, put }) {
      const response = yield call(reqGetUserSurveyListCheckin, payload);
      console.log('model reqGetUserSurveyListCheckin', response);
      yield put({
        type: 'reduceUserSurveyListCheckin',
        payload: response,
      });
      if (cb) {
        cb(response);
      }
    },

    *getUserSurveyList({ payload, cb }, { call, put }) {
      const response = yield call(reqGetUserSurveyList, payload);
      console.log('model getUserSurveyList', response);
      yield put({
        type: 'reduceUserSurveyList',
        payload: response,
      });
      if (cb) {
        cb(response);
      }
    },

    *getCheckinGsData({ payload, extraData }, { call, put }) {
      const response = yield call(reqGetCheckinGsData, payload);
      console.log('model getCheckinGsData', response, extraData);
      yield put({
        type: 'reduceCheckinGsData',
        payload: response,
        extraData,
      });
    },

    *setUserSurveyCheckInEnabled({ payload, cb = () => {} }, { call, put }) {
      console.log('setUserSurveyEnabled', payload.enabled);
      const response = yield call(reqSetUserSurveyCheckInEnabled, payload);
      // yield put({
      //   type: 'reduceUserSurveyEnabled',
      //   payload: response,
      // });
      cb(response.enabled);
    },

    *setUserSurveyEnabled({ payload, cb = () => {} }, { call, put }) {
      console.log('setUserSurveyEnabled', payload.enabled);
      const response = yield call(reqSetUserSurveyEnabled, payload);
      yield put({
        type: 'reduceUserSurveyEnabled',
        payload: response,
      });
      cb(response.enabled);
    },

    *removePushMsg({ payload }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqRemovePushMsg, payload);
      if (response.success) {
        message.success('取消預約成功');
      } else {
        message.warning('取消預約失敗:' + response.msg);
      }
      yield put({ type: 'activityCustomer/getPushMsg', payload });
    },

    *setMontage({ payload, callback }, { call, put }) {
      console.log('model setMontage', payload);
      const response = yield call(reqSetMontage, payload);
      if (callback) {
        callback(response);
      }
      yield put({ type: 'reduceMontage', payload: response.montage });
    },

    *getPushMsg({ payload }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqGetPushMsg, payload);
      yield put({
        type: 'reduceUserPushMsg',
        payload: response,
      });
    },

    *setPushMsg({ payload, callback }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqSetPushMsg, payload);
      callback(response);
      yield put({ type: 'activityCustomer/getPushMsg', payload });
      console.log('put getPushMsg again');
    },

    *setCheckinReady({ payload, callback }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqSetCheckinReady, payload);
      yield put({
        type: 'getUserSurveyListCheckin',
        payload: payload,
      });
    },

    *getSurveyList({ payload, callback }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqGetSurveyList, payload);
      yield put({
        type: 'reduceSurveyList',
        payload: response,
      });
      callback(response);
      // message.success('婚紗照讀取成功');
    },

    *createSurvey({ payload, callback }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqCreateSurvey, payload);
      callback(response);
      // message.success('婚紗照讀取成功');
    },

    *postSurvey({ payload, callback }, { call, put }) {
      console.log('model postSurvey', payload);
      const response = yield call(reqPostSurvey, payload);
      yield put({
        type: 'reduceSurveyList',
        payload: response,
      });
      callback(response);
    },

    *setCashMoneyPrompt({ payload, callback }, { call, put }) {
      console.log('model setCashMoneyPrompt', payload);
      const response = yield call(reqSetCashMoneyPrompt, payload);
      // yield put({
      //   type: 'reduceSurveyList',
      //   payload: response,
      // });
      callback(response);
    },
    *getCashMoneyPrompt({ payload, callback }, { call, put }) {
      console.log('model getCashMoneyPrompt', payload);
      const response = yield call(reqGetCashMoneyPrompt, payload);
      // yield put({
      //   type: 'reduceSurveyList',
      //   payload: response,
      // });
      // console.log('getCashMoneyPrompt', response.prompt)
      callback(response.prompt);
    },

    *getMaterialList({ payload }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqGetMaterialList, payload);
      yield put({
        type: 'reduceMaterialList',
        payload: response,
      });
      // message.success('婚紗照讀取成功');
    },
    *removeMaterial({ payload }, { call, put }) {
      // console.log('model removeImage', payload)
      const response = yield call(reqRemoveMaterial, payload);
      // message.success('婚紗照讀取成功');
    },
    *getFrameList({ payload }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqGetFrameList, payload);
      yield put({
        type: 'reduceFrameList',
        payload: response,
      });
      // message.success('婚紗照讀取成功');
    },
  },
  reducers: {
    reset(state) {
      return {
        imageList: null,
        montageData: {},
        listData: null,
        textLabel: {
          typeText: '婚禮',
          albumText: '婚紗藝廊',
          albumWording: '婚紗照',
          gameTitle: '婚禮遊戲',
        },
        fileList: null,
        materialList: [],
        welcomeMsg: null,
      };
    },
    updateShowInfo(state, action) {
      console.log('updateShowInfo', action.payload);
      return { ...state, showInfo: { loaded: true, ...action.payload } };
    },
    update(state, action) {
      console.log('update reducer', action);
      const isWedding = ['婚宴', '展示'].includes(action.payload.activity.type);
      const textLabel = {
        typeText: isWedding ? '婚禮' : '活動',
        albumText: isWedding ? '婚紗藝廊' : '活動快訊',
        albumWording: isWedding ? '婚紗照' : '活動快訊圖片',
        gameTitle: isWedding ? '婚禮遊戲' : '活動遊戲',
      };

      return { ...state, listData: action.payload.activity, textLabel };
    },
    // reduceWelcomeMsg(state, {payload: {welcomeMsg}}) {
    reduceWelcomeMsg(state, { payload }) {
      console.log('reduceWelcomeMsg', payload);
      return { ...state, welcomeMsg: payload.length > 0 ? payload[0].text : '' };
    },
    reduceMontage(state, action) {
      console.log('save reducers montage', action.payload);
      return { ...state, montageData: action.payload };
    },
    reduceFileList(state, action) {
      console.log('save reducers', action.payload);
      return { ...state, fileList: action.payload };
    },
    /*
    reduceImageList(state, action) {
      console.log('reduceImageList', action)
      return { ...state, imageList: action.payload || null};
    },
    */
    reduceMaterialList(state, action) {
      return { ...state, materialList: action.payload || null };
    },
    reduceUserList(state, action) {
      const userListDict = {};
      (action.payload || []).forEach((e) => {
        userListDict[e.userid] = e;
      });
      return { ...state, userList: action.payload || null, userListDict };
    },

    reduceUserSurveyListCheckin(state, action) {
      const map = action.payload;
      (state.userSurveyList?.user || []).forEach((user) => {
        user.checkinDate = map[user.userid] || undefined;
      });
      /*
      const displayNotification = Object.keys(state.checkinDoneList).length!==0;
      // console.log('reduceUserSurveyListCheckin display', Object.keys(state.checkinDoneList).length, displayNotification);
      // console.log('reduceUserSurveyListCheckin user', state.userSurveyList?.user);
      (state.userSurveyList?.user||[]).forEach((user,idx)=>{
        if(displayNotification && !user.checkinDate && !state.checkinDoneList.hasOwnProperty(user.userid)){
          console.log('displayNotification user', user.checkinDate, !state.checkinDoneList.hasOwnProperty(user.userid), user);
          notification.open({
            message: `${user.displayName} 報到成功`,
            description: `${user.displayName} ${moment(map[user.userid]).format('MM-DD HH:mm')}`,
            onClick: () => {
              // console.log('Notification Clicked!');
            },
            duration: 10,
          });
        }
        state.userSurveyList.user[idx].checkinDate = map[user.userid] || undefined;
      });
      state.checkinDoneList = map
      // return cloneDeep({ ...state });
      */
      return { ...state };
    },

    reduceUserSurveyList(state, action) {
      const qs_map = (action.payload.qs || [])
        .filter(({ choices }) => choices && choices.length > 0)
        .reduce((acc, cur) => {
          acc[cur.name] = cur.choices.reduce((acc2, cur2) => {
            acc2[cur2.value] = cur2.text;
            return acc2;
          }, {});
          if (cur.hasNone) {
            acc[cur.name]['none'] = cur.noneText;
          }
          return acc;
        }, {});
      return { ...state, userSurveyList: { qs_map, ...action.payload } || null };
    },

    reduceCheckinGsData(state, action) {
      // TODO: UserList come after gs data concurrency
      const checkinGsDataMap = {};
      const checkinGsDataList = [];

      const { headers } = action.payload;
      const { gsKeyCol, gsNameCol } = action.extraData;
      console.log('gsKeyCol', gsKeyCol, gsNameCol);
      console.log('checkinGsDataList', action.payload.rows);
      //build map
      const tmpKeyDict = {};
      (state.userSurveyList?.user || []).forEach((e) => {
        if (e.hasOwnProperty('gsKeyValue')) {
          const key = e.gsKeyValue;
          tmpKeyDict[key] = 1;
        }
      });

      (action.payload.rows || []).forEach((e) => {
        const key = e[gsKeyCol];
        const d = {};
        headers.forEach((h, i) => (d[h] = e[i]));
        if (!tmpKeyDict.hasOwnProperty(key)) {
          checkinGsDataList.push({
            displayName: e[gsNameCol],
            external: true,
            ...d,
          });
        }
        checkinGsDataMap[key] = d;
      });

      return {
        ...state,
        checkinGsData: action.payload,
        checkinGsDataMap,
        checkinGsDataList,
      };
    },

    reduceUserSurveyEnabled(state, action) {
      return {
        ...state,
        userSurveyList: { ...state.userSurveyList, enabled: action.payload.enabled },
      };
    },
    reduceUserPushMsg(state, action) {
      return { ...state, userPushMsg: action.payload || null };
    },
    reduceFrameList(state, action) {
      console.log('reduceFrameList', action);
      return { ...state, frameList: action.payload || null };
    },
  },
};
export default Model;
