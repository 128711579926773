import React from 'react';
import { message, notification } from 'antd';
import { reqExchangePost } from '@/services/exchange';
import { history, Link } from 'umi';

const Model = {
  namespace: 'exchange',
  state: {
    state: '', //'', auth, already, new, finished, err
    err: '',
    exchangeKey: '',
    exchange: {},
    saler: {},
    activity: {},
    user: {},
    list: [],
  },
  effects: {
    *exchangeList({ payload: { token } }, { call, put }) {
      const response = yield call(reqExchangePost, { token, action: 'list' });
      // alert(response?.list?.length);
      if (response?.list?.length == 1 && response.list[0].activity !== '' && response.list[0].pwd) {
        history.push(`/c/${response.list[0].activity}/${response.list[0].pwd}`, {});
      } else {
        yield put({ type: 'statusUpdate', payload: { state: 'list', list: response.list } });
      }
    },

    *exchangeAuth({ payload: { token, key } }, { call, put }) {
      //token, key

      yield put({ type: 'statusUpdate', payload: { state: 'auth' } });

      const response = yield call(reqExchangePost, { token, key, action: 'auth' });

      if (!response.success) {
        yield put({ type: 'statusUpdate', payload: { state: 'err', err: response.msg } });
        return;
      }

      if (response.isCreator) {
        notification.open({
          message: '綁定發放單位成功',
          description: '已經將此喜帖設置為' + response?.saler?.display,
          onClick: () => {},
          placement: 'bottomRight',
          duration: 6,
        });
      }

      if (response.activity === null) {
        yield put({
          type: 'statusUpdate',
          payload: {
            state: 'new',
            exchange: response.exchange,
            saler: response.saler,
            user: response.user,
          },
        });
        return;
      } else {
        yield put({
          type: 'exchangeList',
          payload: { token },
        });
        // yield put({
        //   type: 'statusUpdate',
        //   payload: {
        //     state: 'finished',
        //     exchange: response.exchange,
        //     saler: response.saler,
        //     user: response.user,
        //     activity: response.activity,
        //   }
        // });
        return;
      }
      console.log('exchangeAuth response', response);
    },

    *exchangeCreate({ payload: { token, key, info }, callback }, { call, put }) {
      const response = yield call(reqExchangePost, { token, key, info, action: 'use' });

      yield put({
        type: 'authReducer',
        payload: response,
      });

      if (!response.success) {
        yield put({ type: 'statusUpdate', payload: { state: 'err', err: response.msg } });
        return;
      }
      if (callback) {
        callback();
      }

      notification.open({
        message: 'LINE喜帖建立成功',
        description: '您可以傳送此官方帳號給您的親友，邀請參加您的婚禮！',
        onClick: () => {},
        placement: 'bottomRight',
        duration: 6,
      });

      yield put({
        type: 'exchangeAuth',
        payload: { token, key },
      });
    },

    *exchangeJoinAdmin({ payload: { token, key }, callback }, { call, put }) {
      const response = yield call(reqExchangePost, { token, key, action: 'join' });
      // yield put({
      //   type: 'authReducer',
      //   payload: response,
      // });

      yield put({
        type: 'exchangeAuth',
        payload: { token, key },
      });

      if (response.success) {
        notification.open({
          message: '您已經成功加入管理員',
          description: '您現在是此官方帳號的管理員囉',
          onClick: () => {},
          duration: 3,
          placement: 'bottomRight',
        });
        return;
      }

      notification.open({
        message: '加入管理員發生錯誤',
        description: response.msg,
        onClick: () => {},
        duration: 5,
        placement: 'bottomRight',
      });
    },
  },

  reducers: {
    statusUpdate(state, action) {
      // console.log('authReducer', action.payload)
      return { ...state, ...action.payload };
    },
  },
};

export default Model;
