import { message } from 'antd';
import {
  reqGetMaterialList,
} from '@/services/live';

const Model = {
  namespace: 'liveModel',
  state: {
    imageList: null,
    listData: null, //activitydata
  },
  effects: {
    *getFrameList({ payload }, { call, put }) {
      // console.log('model getImageList', payload)
      const response = yield call(reqGetFrameList, payload);
      yield put({
        type: 'reduceFrameList',
        payload: response,
      });
    },
  },
  reducers: {
    reset(state) {
      return {
        imageList: null,
        montageData: {},
      };
    },
    update(state, action) {
      console.log('update reducer', action);
      return { ...state, listData: action.payload.activity };
    },
  },
};
export default Model;
