import { request } from 'umi';

export async function welcomeMsgForm(params) {
  return request('/customer/welcomeMsg/' + params.activity + '/' + params.pwd, {
    method: 'GET',
    data: params,
  });
}
export async function setWelcomeMsgForm(params) {
  return request('/customer/welcomeMsg/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}
export async function setProgressForm(params) {
  return request('/customer/set_progress/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}
export async function reqSetUserPermission(params) {
  return request('/customer/set_userpermission/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}
export async function reqSetUserRole(params) {
  return request('/customer/set_userrole/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}
export async function reqRefresh(params) {
  console.log('reqRefresh');
  return request(
    '/customer/activity_info/' + params.activity + '/' + params.pwd + '?r=' + Math.random(),
    {
      method: 'GET',
      data: params,
    },
  );
}
/*
export async function reqSetupOnlineService(params) {
  return request('/customer/online_service_code/'+params.activity+'/'+params.pwd, {
    method: 'GET',
    data: params,
  });
}*/
export async function reqGetUserSurveyListCheckin(params) {
  return request('/customer/user_survey_list_checkin/' + params.activity + '/' + params.pwd, {
    params: params,
    method: 'GET',
  });
}
export async function reqGetUserSurveyList(params) {
  return request('/customer/user_survey_list/' + params.activity + '/' + params.pwd, {
    params: params,
    method: 'GET',
  });
}
export async function reqGetCheckinGsData(params) {
  return request('/customer/gs_read/' + params.activity + '/' + params.pwd, {
    method: 'GET',
    params,
  });
}
export async function reqSetCheckinReady(params) {
  return request('/customer/set_checkin_ready/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}
export async function reqAddUsersTag(params) {
  return request('/customer/add_users_tag/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}
export async function reqRemoveUsersTag(params) {
  return request('/customer/remove_users_tag/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}
export async function reqRemoveUserSurveyAns(params) {
  return request('/customer/remove_survey_ans/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqSetUserSurveyCheckInEnabled(params) {
  return request('/customer/user_survey_checkin_enabled/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqSetUserSurveyEnabled(params) {
  return request('/customer/user_survey_enabled/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqGetPushMsg(params) {
  return request('/customer/get_push_msgs/' + params.activity + '/' + params.pwd, {
    method: 'GET',
  });
}

export async function reqSetPushMsg(params) {
  return request('/customer/set_push_msgs/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqSetMontage(params) {
  return request('/customer/set_montage/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqRemovePushMsg(params) {
  return request('/customer/remove_push_msgs/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqGetUserList(params) {
  return request(
    '/customer/user_list/' + params.activity + '/' + params.pwd + (params.admin ? '?admin=1' : ''),
    {
      method: 'GET',
      // params,
    },
  );
}

export async function reqGetSurveyList(params) {
  return request('/customer/survey_list/' + params.activity + '/' + params.pwd, {
    method: 'GET',
  });
}

export async function reqCreateSurvey(params) {
  return request('/customer/create_survey/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqQueryShowInfo(params) {
  return request('/customer/homepage/' + params.activity + '/' + params.pwd, {
    method: 'GET',
  });
}

export async function reqPostSurvey(params) {
  return request('/customer/survey_update/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqGetCashMoneyPrompt(params) {
  return request('/customer/cash_money_prompt/' + params.activity + '/' + params.pwd, {
    method: 'GET',
    data: params,
  });
}

export async function reqSetCashMoneyPrompt(params) {
  return request('/customer/cash_money_prompt/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function submitLotteryForm(params) {
  return request('/customer/update_lottery/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function submitGameLotteryForm(params) {
  return request('/customer/update_game_lottery/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: params,
  });
}

export async function submitWelcomeImage(params) {
  const formData = new FormData();
  console.log('submitWelcomeImage', params);
  formData.append('file', params.file, params.fn);
  return request('/customer/update_welcome_image/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: formData,
  });
}

export async function submitUploadFile(params) {
  const formData = new FormData();
  formData.append('type', params.type);
  formData.append('file', params.file);
  return request('/customer/upload_file/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: formData,
  });
}

export async function submitWelcomeVideo(params) {
  const formData = new FormData();
  formData.append('file', params.file);
  return request('/customer/update_welcome_video/' + params.activity + '/' + params.pwd, {
    method: 'POST',
    data: formData,
  });
}

export async function getFileListForm(params) {
  // console.log('getFileListForm params', params)
  return request('/customer/image_list/' + params.activity + '/' + params.pwd, {
    method: 'GET',
    data: params,
  });
}

export async function updateForm({ activity, pwd, ...params }) {
  // console.log('getFileListForm params', params)
  return request('/customer/update_activity/' + activity + '/' + pwd, {
    method: 'POST',
    data: params,
  });
}

export async function reqGetMaterialList(params) {
  // console.log('getFileListForm params', params)
  return request('/customer/material_list/' + params.activity + '/' + params.pwd, {
    method: 'GET',
    data: params,
  });
}

export async function reqGetFrameList(params) {
  // console.log('getFileListForm params', params)
  return request('/customer/frame_list/' + params.activity + '/' + params.pwd, {
    method: 'GET',
    data: params,
  });
}

export async function reqRemoveMaterial(params) {
  // console.log('getFileListForm params', params)
  return request(
    '/customer/material_remove/' + params.activity + '/' + params.pwd + '/' + params.key,
    {
      method: 'GET',
      // data: params,
    },
  );
}
