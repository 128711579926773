import { request } from 'umi';

export async function reqGetSurvey(params) {
  return request('/user/survey/query/'+params.key + (params.surveyId?`?surveyId=${params.surveyId}` : ''), {
    method: 'GET',
  });
}
export async function reqPostSurveyAns({key, ans, surveyId}) {
  return request('/user/survey/submit/'+key, {
    method: 'POST',
    data: {ans, surveyId},
  });
}
export async function reqPostBindGsData({key, keyValue, surveyId}) {
  return request('/user/checkin/bind/'+key, {
    method: 'POST',
    data: {keyValue, surveyId},
  });
}
export async function reqGetCustomerCheckinGsData(params) {
  return request('/api/gs_read/'+params.activity , {
    method: 'GET',
    params,
  });
}

export async function reqUploadSurveyFile(params) {
  const formData = new FormData();
  formData.append('surveyId', params.surveyId);
  formData.append('name', params.name);
  formData.append('file', params.file);

  return request('/user/survey/upload/'+params.key, {
    method: 'POST',
    data: formData,
  });

}