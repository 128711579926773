import { backendUrl } from './utils';
import { notification } from 'antd';

const codeMessage = {
  200: '伺服器成功返回請求的數據。',
  201: '新建或修改數據成功。',
  202: '一個請求已經進入後臺排隊（異步任務）。',
  204: '刪除數據成功。',
  400: '發出的請求有錯誤，伺服器沒有進行新建或修改數據的操作。',
  401: '用戶沒有權限（令牌、用戶名、密碼錯誤）。',
  403: '用戶得到授權，但是訪問是被禁止的。',
  404: '發出的請求針對的是不存在的記錄，伺服器沒有進行操作。',
  406: '請求的格式不可得。',
  410: '請求的資源被永久刪除，且不會再得到的。',
  422: '當創建一個對象時，發生一個驗證錯誤。',
  500: '伺服器發生錯誤，請檢查伺服器。',
  502: '網關錯誤。',
  503: '服務不可用，伺服器暫時過載或維護。',
  504: '網關超時。',
};
/**
 * 異常處理程序
 */

const errorHandler = error => {
  const { response } = error;
  console.log('err', error)
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `請求錯誤 ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的網路發生異常，無法連接伺服器',
      message: '網路異常',
    });
  }

  return response;
};
/**
 * 配置request請求時的默認參數
 */

const request = {
  errorHandler,
  prefix: backendUrl(),
  // 默認錯誤處理
  credentials: 'include', // 默認請求是否帶上cookie
};

export default request;