import { PageLoading } from '@ant-design/pro-layout';
import { history, Link } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
// import PageLoading from '@/pages/PageLoading';

import { BookOutlined, LinkOutlined } from '@ant-design/icons';

const isDev = process.env.NODE_ENV === 'development';

// export customized request
export {default as request} from '@/utils/request';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <div>test</div>,
};
/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */

export async function getInitialState() {
  // console.log('menu', menu);
  // return {menu: '1234'}
  /*
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      return msg.data;
    } catch (error) {
      history.push(loginPath);
    }

    return undefined;
  }; // 如果是登录页面，不执行

  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: {},
    };
  }
*/
  // const [menu, setMenu] = useState([
  //     {
  //       path: '/',
  //       name: 'welcome',
  //       icon: 'smile',
  //       children: [
  //         {
  //           path: '/welcome',
  //           name: 'one',
  //           icon: 'smile',
  //           children: [
  //             {
  //               path: '/welcome/welcome',
  //               name: 'two',
  //               icon: 'smile',
  //               exact: true,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       path: '/c/190915_test222/hijufEShhdqGhTRM',
  //       name: 'official',
  //       icon: 'heart',
  //     },
  //     {
  //       path: '/c/190915_test222/hijufEShhdqGhTRM/manage',
  //       name: 'manage',
  //       icon: 'heart',
  //     },
  // ])
  // console.log('menu', menu);
  return {
  };
} // ProLayout 支持的api https://procomponents.ant.design/components/layout
/*
export const layout = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    waterMarkProps: {
      content: "拍拍印",
    },
    footerRender: () => <Footer />,
    onPageChange: () => {
      // const { location } = history; // 如果没有登录，重定向到 login
      // if (!initialState?.currentUser && location.pathname !== loginPath) {
      //   history.push(loginPath);
      // }
    },
    links: isDev
      ? [
          <Link to="/umi/plugin/openapi" target="_blank">
            <LinkOutlined />
            <span>OpenAPI 文档</span>
          </Link>,
          <Link to="/~docs">
            <BookOutlined />
            <span>业务组件文档</span>
          </Link>,
        ]
      : [],
    // menu: initialState.menu,

    menu: {
      // Re-execute request whenever initialState?.currentUser?.userid is modified
      params: {
        initialState,
      },
      request: async (params, defaultMenuData) => {
        // initialState.currentUser contains all user information
        // const menuData = await fetchMenuData();
        const { initialState } = params;
        alert('ggyy init')
        console.log('ggyy init', initialState)
        return [
          {
            path: '/',
            name: 'welcome',
            icon: 'smile',
            children: [
              {
                path: '/welcome',
                name: 'one',
                icon: 'smile',
                children: [
                  {
                    path: '/welcome/welcome',
                    name: 'two',
                    icon: 'smile',
                    exact: true,
                  },
                ],
              },
            ],
          },
          {
            path: '/demo',
            name: 'demo',
            icon: 'heart',
          },
          {
            path: '/c/190915_test222/hijufEShhdqGhTRM',
            name: 'official',
            icon: 'heart',
          },
          {
            path: '/c/190915_test222/hijufEShhdqGhTRM/manage',
            name: 'manage',
            icon: 'heart',
          },
      ]},
    },
    menu: {
      // Re-execute request whenever initialState?.currentUser?.userid is modified
      params: {
        // userId: initialState?.currentUser?.userid,
        initialState,
      },
      // request: menuGenerator,
      request: async (params, defaultMenuData) => {
        // initialState.currentUser contains all user information
        // const menuData = await fetchMenuData();
        return initialState.menu;
      }
    },
      PageLoading: <div>asdasd</div>,

    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings,
  };
};
*/