import { request } from 'umi';

// export async function reqLiffTokenAuth(activity, token) {
//   return request(`/api/invitation_auth_liff/${activity}`, {
//     method: 'POST',
//     data: {token},
//   });
// }

export async function reqExchangeManagePost(data) {
  return request(`/api/exchangeManage`, {
    method: 'POST',
    data,
  });
}