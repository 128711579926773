import { parse } from 'querystring';
import { moment } from '@/utils/common';
/* eslint no-useless-escape:0 import/prefer-default-export:0 */

const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);
export const isAntDesignPro = () => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }

  return window.location.hostname === 'preview.pro.ant.design';
}; // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性

export const isAntDesignProOrDev = () => {
  const { NODE_ENV } = process.env;

  if (NODE_ENV === 'development') {
    return true;
  }

  return isAntDesignPro();
};

export const isProduction = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV !== 'development';
};

export const backendUrl = () => {
  return isProduction() ? 'https://backend2.linein.cc' : 'https://linein.me';
  // return isProduction() ? 'https://backend.linein.cc' : 'http://home.apponline.tw:7001';
};

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const swapArrayElements = (a, x, y) =>
  (a[x] && a[y] && [...a.slice(0, x), a[y], ...a.slice(x + 1, y), a[x], ...a.slice(y + 1)]) || a;

export const zeroStartString = (s) => s.length > 1 && s.startsWith('0');
export const isNumber = (n) => /^-?[\d.]+(?:e-?\d+)?$/.test(n) && !zeroStartString(n.toString());
export const tryReturnNumber = (n) => (isNumber(n) ? parseInt(n) : n);

export const processDateTimeString = (s, date) =>
  s
    .replace('{datetime}', moment(date).format('YYYY-MM-DD HH:mm'))
    .replace('{date}', moment(date).format('YYYY-MM-DD'));
