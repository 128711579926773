import { message } from 'antd';
import {
  reqGetSurvey,
  reqPostSurveyAns,
  reqGetCustomerCheckinGsData,
  reqPostBindGsData,
} from '@/services/survey';
import { isEqual, reject } from 'lodash';

const Model = {
  namespace: 'surveySubmit',
  state: {
    survey: {
      template: null,
      layout: null,
      qs: null,
      surveyId: null,
      activity: null,
    },
    ans: null,
    user: {},
    checkinGsData: null,
    checkinGsDataMap: {},
    checkinGsDataList: [],
  },
  effects: {
    *querySurvey({ payload, cb }, { call, put }) {
      console.log('model querySurvey');
      const response = yield call(reqGetSurvey, payload);

      console.log('model querySurvey response', response);
      yield put({
        type: 'update',
        payload: response,
      });

      if (cb) cb(response);
    },

    *submitSurvey({ payload, res, rej }, { call, put }) {
      console.log('model submitSurvey', payload, res, rej);
      try {
        yield call(reqPostSurveyAns, payload);
        message.success('提交成功');
        res();
        yield put({
          type: 'querySurvey',
          payload,
        });
      } catch (err) {
        alert('提交失敗:' + err.toString());
        rej();
      }
    },

    *getCustomerCheckinGsData({ payload, extraData }, { call, put }) {
      const response = yield call(reqGetCustomerCheckinGsData, payload);
      console.log('model getCheckinGsData', response, extraData);
      yield put({
        type: 'reduceCheckinGsData',
        payload: response,
        extraData,
      });
    },

    *bindGsData({ payload, cb = () => {} }, { call, put }) {
      const response = yield call(reqPostBindGsData, payload);
      console.log('model reqPostBindGsData', response);
      cb(response);
    },
  },
  reducers: {
    reset(state) {
      return {
        qs: null,
        layout: null,
        ans: null,
      };
    },

    update(state, action) {
      console.log('update', action.payload);
      if (isEqual(action.payload.survey, state.survey) && isEqual(action.payload.ans, state.ans)) {
        return state;
      }
      return { ...state, ...action.payload };
    },

    reduceCheckinGsData(state, action) {
      // TODO: UserList come after gs data concurrency
      const checkinGsDataMap = {};
      const checkinGsDataList = [];

      const { headers } = action.payload;
      const { gsKeyCol, gsNameCol } = action.extraData;
      // console.log('gsKeyCol', gsKeyCol, gsNameCol);
      // console.log('checkinGsDataList', action.payload.rows);
      //build map
      const tmpKeyDict = {};

      (action.payload.rows || []).forEach((e) => {
        const key = e[gsKeyCol];
        const d = {};
        headers.forEach((h, i) => (d[h] = e[i]));
        if (!tmpKeyDict.hasOwnProperty(key)) {
          checkinGsDataList.push({
            displayName: e[gsNameCol],
            keyValue: key,
            external: true,
            ...d,
          });
        }
        checkinGsDataMap[key] = d;
      });

      return {
        ...state,
        checkinGsData: action.payload,
        checkinGsDataMap,
        checkinGsDataList,
      };
    },
  },
};
export default Model;
