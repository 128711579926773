import React from 'react';
import { message, notification } from 'antd';
import { reqExchangeManagePost } from '@/services/exchangeManage';

const Model = {
  namespace: 'exchangeManage',
  state: {
    state: '',  //'', auth, already, new, finished, err
    list: [],
  },
  effects: {
    *list({ payload:{token}, callback }, { call, put }) {
      const response = yield call(reqExchangeManagePost, {token, action:'list'});
      yield put({ type: 'statusUpdate', payload: {state: 'list', list: response.list} });
      callback();
    },

    *create({ payload:{token, key, info}, callback }, { call, put }) {
      const response = yield call(reqExchangeManagePost, {token, key, info, action:'create'});

      if(!response.success){
        yield put({ type: 'statusUpdate', payload: {state: 'err', err: response.msg} });
        return;
      }

      notification.open({
        message: 'LINE喜帖建立成功',
        description: "請讓新人掃描此qrcode進行兌換！",
        onClick: () => {

        },
        placement: 'bottomRight',
        duration: 6,
      })

      callback(response.data);
    },

  },

  reducers: {
    statusUpdate(state, action) {
      // console.log('authReducer', action.payload)
      return { ...state, ...action.payload };
    },
  },
};

export default Model;
