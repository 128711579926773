import { Select, Skeleton } from 'antd';

import moment from 'moment-timezone';
moment.tz.setDefault("Asia/Taipei");
export { moment };

const { Option } = Select;

export const distinctPush = (arr, ele) => {
  const push = (arr, ele) => {
    if (!arr.includes(ele)) {
      arr.push(ele);
    }
  };
  if (Array.isArray(ele)) {
    ele.forEach((v) => push(arr, v));
  } else {
    push(arr, ele);
  }
};

export const pull = (arr, eles) => {
  return arr.filter((v) => !eles.includes(v));
};

export const time_options = [
  <Option key="06:00" value="06:00">
    06:00
  </Option>,
  <Option key="06:30" value="06:30">
    06:30
  </Option>,
  <Option key="07:00" value="07:00">
    07:00
  </Option>,
  <Option key="07:30" value="07:30">
    07:30
  </Option>,
  <Option key="08:00" value="08:00">
    08:00
  </Option>,
  <Option key="08:30" value="08:30">
    08:30
  </Option>,
  <Option key="09:00" value="09:00">
    09:00
  </Option>,
  <Option key="09:30" value="09:30">
    09:30
  </Option>,
  <Option key="10:00" value="10:00">
    10:00
  </Option>,
  <Option key="10:30" value="10:30">
    10:30
  </Option>,
  <Option key="11:00" value="11:00">
    11:00
  </Option>,
  <Option key="11:30" value="11:30">
    11:30
  </Option>,
  <Option key="12:00" value="12:00">
    12:00
  </Option>,
  <Option key="12:30" value="12:30">
    12:30
  </Option>,
  <Option key="13:00" value="13:00">
    13:00
  </Option>,
  <Option key="13:30" value="13:30">
    13:30
  </Option>,
  <Option key="14:00" value="14:00">
    14:00
  </Option>,
  <Option key="14:30" value="14:30">
    14:30
  </Option>,
  <Option key="15:00" value="15:00">
    15:00
  </Option>,
  <Option key="15:30" value="15:30">
    15:30
  </Option>,
  <Option key="16:00" value="16:00">
    16:00
  </Option>,
  <Option key="16:30" value="16:30">
    16:30
  </Option>,
  <Option key="17:00" value="17:00">
    17:00
  </Option>,
  <Option key="17:30" value="17:30">
    17:30
  </Option>,
  <Option key="18:00" value="18:00">
    18:00
  </Option>,
  <Option key="18:30" value="18:30">
    18:30
  </Option>,
  <Option key="19:00" value="19:00">
    19:00
  </Option>,
  <Option key="19:30" value="19:30">
    19:30
  </Option>,
  <Option key="20:00" value="20:00">
    20:00
  </Option>,
  <Option key="20:30" value="20:30">
    20:30
  </Option>,
  <Option key="21:00" value="21:00">
    21:00
  </Option>,
  <Option key="21:30" value="21:30">
    21:30
  </Option>,
  <Option key="22:00" value="22:00">
    22:00
  </Option>,
  <Option key="22:30" value="22:30">
    22:30
  </Option>,
  <Option key="23:00" value="23:00">
    23:00
  </Option>,
  <Option key="23:30" value="23:30">
    23:30
  </Option>,
];

export const gs = (xs, sm, md, lg, xl) => ({ xs, sm, md, lg, xl });

export const gs_12_12_6 = gs(12, 12, 6, 6, 6);
export const gs_24_12_6 = gs(24, 12, 6, 6, 6);
export const gs_24_8_8 = gs(24, 8, 8, 8, 8);
export const gs_12_8_8 = gs(12, 8, 8, 8, 8);

export const panelsConfig = {
  basic_info: {
    name: '基本資訊',
    short: '基',
    color: 'magenta',
    rank: 0,
  },
  activity: {
    name: '活動設定',
    short: '設',
    color: 'orange',
    rank: 1,
  },
  material: {
    name: '邊框素材',
    short: '框',
    color: 'green',
    rank: 2,
  },
  offtext: {
    name: '謝客詞',
    short: '謝',
    color: 'cyan',
    rank: 3,
  },
  activity_confirm: {
    name: '行前確認',
    short: '確',
    color: 'blue',
    rank: 4,
  },
  payment: {
    name: '線上禮金',
    short: '金',
    color: 'yellow',
    rank: 5,
  },
  game_setting: {
    name: '遊戲設定',
    short: '遊戲',
    color: 'yellow',
    rank: 5,
  },
  montage_setting: {
    name: '蒙太奇設定',
    short: '蒙設',
    color: 'yellow',
    rank: 5,
  },

  try_printer: {
    name: '體驗拍貼系統',
    short: '體',
    color: 'red',
    rank: -5,
  },
  orderPrinter: {
    name: '訂購拍貼機',
    short: '拍',
    color: 'magenta',
    rank: -4,
  },
  orderInvitation: {
    name: '訂購電子喜帖',
    short: '帖',
    color: 'blue',
    rank: -3,
  },
  basicId: {
    name: '已產生帳號',
    short: '官',
    color: 'green',
    rank: -2,
  },
  cfgWebUpload: {
    name: 'MINI版',
    short: 'MINI',
    color: 'blue',
    rank: -1,
  },
  orderMontage: {
    name: '訂購蒙太奇',
    short: '蒙',
    color: '#ffbb96',
    rank: 0,
  },
};

export const panelsList = Object.keys(panelsConfig).map((e) => ({ value: e, ...panelsConfig[e] }));
export const panelsOptions = panelsList.map((e) => (
  <Option key={e.value} value={e.value}>
    {e.name}
  </Option>
));

export const userEditableName = {
  date: '活動日期',
  title: '官方帳號名稱',
  groom_name: '新郎姓名',
  bride_name: '新娘姓名',
  groom_ename: '新郎英文名',
  bride_ename: '新娘英文名',
  start_time: '開始時間',
  end_time: '結束時間',
  contact_it: '故障排除',
  contact_location: '婚企(場地)',
  contact_lottery: '抽獎人',
  contact_order: '訂購人',
  contact_presenter: '主持人',
  location_addr: '宴客地址',
  location_area: '宴客地區',
  location_readable: '宴客地點(飯店/廳別)',
  card_num: '桌卡數量',
  frames_word: '文字框',
  frames_person: '人物框',
  // 'frameOrder': '邊框順序',
  defaultPrintNumber: '列印額度',

  locationGoogleUrl: '宴客地圖網址(電子喜帖',

  material_note: '邊框備註',
  tail_fee_last5: '轉帳末5碼',
  tail_fee_date: '轉帳日期',

  customerCheckFrame: '確認邊框',
  customerCheckLottery: '確認抽獎',
  customerCheckPower: '確認電源',
  customerCheckDistance: '確認距離',
  customerCheckComputer: '確認電腦',
  customerCheckMeal: '確認餐點',
  customerCheckOverflow: '確認超時處理方式',
  customerCheckOverflowSelect: '超時處理方式',
  customerCheckCoupon: '確認提供加值碼',
  customerCheckCouponSelect: '確認提供加值碼選項',

  offText: '謝客詞',
  offTextTime: '謝客詞時間',
  try_printer: '體驗拍貼系統',
};

export const userEditableList = Object.keys(userEditableName);

const transTitle = (key) => (userEditableName[key] ? userEditableName[key] : key);

export const userEditableDesc = Object.keys(userEditableName).map((name) => ({
  key: name,
  title: transTitle(name),
  description: name,
  disabled: false,
}));

export const two_val_check = (key) => (data, ref) => ref[key] === undefined ? data[key] : ref[key];

export const makeid = (length) => {
  var result = '';
  var characters = 'abcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const invitation_menu_config = [
  {
    key: 'free0-map-gallery-printer',
    title: '粉紅-導航-婚紗',
    fn: 'free0-map-gallery-printer',
  },
  {
    key: 'free0-map-gallery-survey-printer',
    title: '粉紅-導航-婚紗-問卷',
    fn: 'free0-map-gallery-survey-printer',
  },
  {
    key: 'free0-map-printer',
    title: '粉紅-導航',
    fn: 'free0-map-printer',
  },
  {
    key: 'free0-map-survey-printer',
    title: '粉紅-導航-問卷',
    fn: 'free0-map-survey-printer',
  },
  {
    key: 'free1-map-gallery-printer',
    title: '大約-導航-婚紗',
    fn: 'free1-map-gallery-printer',
  },
  {
    key: 'free1-map-gallery-survey-printer',
    title: '大約-導航-婚紗-問卷',
    fn: 'free1-map-gallery-survey-printer',
  },
  {
    key: 'free1-map-printer',
    title: '大約-導航',
    fn: 'free1-map-printer',
  },
  {
    key: 'free1-map-survey-printer',
    title: '大約-導航-問卷',
    fn: 'free1-map-survey-printer',
  },
  {
    key: 'free2-map-survey-printer',
    title: '中式-導航-問卷',
    fn: 'free2-map-survey-printer',
  },
  {
    key: 'free2-map-gallery-printer',
    title: '中式-導航-婚紗',
    fn: 'free2-map-gallery-printer',
  },
  {
    key: 'free2-map-gallery-survey-printer',
    title: '中式-導航-婚紗-問卷',
    fn: 'free2-map-gallery-survey-printer',
  },
  {
    key: 'free2-map-printer',
    title: '中式-導航',
    fn: 'free2-map-printer',
  },
  {
    key: 'free3-map-gallery-printer',
    title: '大栽-導航-婚紗',
    fn: 'free3-map-gallery-printer',
  },
  {
    key: 'free3-map-gallery-survey-printer',
    title: '大栽-導航-婚紗-問卷',
    fn: 'free3-map-gallery-survey-printer',
  },
  {
    key: 'free4-map-gallery-survey-printer',
    title: '直簡-導航-婚紗-問卷',
    fn: 'free4-map-gallery-survey-printer',
  },
  {
    key: 'f0-map',
    title: '粉紅-導航',
    fn: 'f0-map',
  },
  {
    key: 'f0',
    title: '粉紅-問卷+導航',
    fn: 'f0',
  },
  {
    key: 'f0',
    title: '粉紅-問卷+導航',
    fn: 'f0',
  },
  {
    key: 'f0-gallery-printer',
    title: '粉紅-婚紗+問卷+導航',
    fn: 'f0-gallery-printer',
  },
  /*
  {
    key:'a1',
    title: '喜帖-大理石',
    fn:'a1'
  },
  {
    key:'a2',
    title: '婚禮-中式',
    fn:'a2'
  },
  {
    key:'a3',
    title: '婚禮-高雅',
    fn:'a3'
  },
  {
    key:'vend_romancelife',
    title: '廠商-浪漫一生',
    fn:'vend_romancelife'
  },
  {
    key:'a4',
    title: '婚禮-大理石2',
    fn:'a4'
  },
  {
    key:'p1',
    title: '喜帖-大理石',
    fn:'p1'
  },*/
];

export const menu_config = [
  {
    key: 'menu_lottery_gallery',
    title: '婚禮-抽獎+婚紗',
    fn: 'menu_lottery_gallery.jpg',
  },
  {
    key: 'menu_lottery',
    title: '婚禮-抽獎',
    fn: 'menu_lottery.jpg',
  },
  {
    key: 'menu_gallery',
    title: '婚禮-婚紗',
    fn: 'menu_gallery.jpg',
  },
  {
    key: 'menu_simple',
    title: '婚禮-無抽獎婚紗',
    fn: 'menu_simple.jpg',
  },
  {
    key: 'menu_activity',
    title: '活動場-抽獎+快訊',
    fn: 'menu_activity.jpg',
  },
  {
    key: 'menu_activity_simple',
    title: '活動場',
    fn: 'menu_activity_simple.jpg',
  },
  {
    key: 'menu_lottery_gallery_invitation',
    title: '喜帖+抽獎+婚紗',
    fn: 'menu_lottery_gallery_invitation.jpg',
  },
  {
    key: 'menu_lottery_gallery_invitation_quiz',
    title: '喜帖+抽獎+婚紗+快問快答',
    fn: 'menu_lottery_gallery_invitation_quiz.jpg',
  },
  {
    key: 'menu_gallery_invitation',
    title: '喜帖+婚紗',
    fn: 'menu_gallery_invitation.jpg',
  },
];

export const default_menu_config = [
  {
    key: '',
    title: '無選單',
  },
  {
    key: 'printer',
    title: '拍拍印',
  },
  {
    key: 'invitation',
    title: '電子喜帖',
  },
];

export const orderStates = [
  {
    name: 'orderPrinter',
    display: '拍貼機',
  },
  {
    name: 'orderInvitation',
    display: '電子喜帖',
  },
];

export const todo_types_config = {
  諮詢: { display: '諮詢紀錄', color: '#fafafa', textColor: '#000' },
  客服: { display: '客服類待辦', color: 'pink', textColor: '#820014' },
  美工: { display: '美工類待辦', color: '#d3adf7', textColor: '#531dab' },
  工程: { display: '工程類待辦', color: '#91d5ff', textColor: '#0050b3' },
  現場: { display: '現場類待辦', color: '#b7eb8f', textColor: '#135200' },
};
export const todo_types = Object.keys(todo_types_config);

export const activity_status_config = {
  填單: { color: 'pink' },
  對帳: { color: 'orange' },
  正常: { color: 'green' },
  關閉: { color: '#8c8c8c' },
  取消: { color: '#CCCCCC' },
  延期: { color: '#999999' },
};
export const activity_status = Object.keys(activity_status_config);

export const activity_types_config = {
  婚宴: { display: '婚宴', color: 'pink' },
  活動: { display: '活動', color: 'yellow' },
  展示: { display: '展示', color: 'green' },
  長租: { display: '長租', color: 'blue' },
};
export const activity_types = Object.keys(activity_types_config);

export const customerTypeOptions = activity_types.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));
export const customerStatusOptions = activity_status.map((e) => (
  <Option key={e} value={e}>
    {e}
  </Option>
));

export const preventClick = {
  onClick: (e, o) => {
    e && e.stopPropagation && e.stopPropagation(); //e.nativeEvent.stopImmediatePropagation(); e.preventDefault();
    o && o.stopPropagation && o.stopPropagation(); //e.nativeEvent.stopImmediatePropagation(); e.preventDefault();
  },
};

export const PageSpin = (
  <div>
    <Skeleton active />
  </div>
);
